window.htr_claimProfileModal = (modalContainerId, companyName) => {
  let confirmationToast = `
  <div id="claim-profile-modal-confirmation-toast" class="modal-wrapper"  style="display: none">
    <div class="modal">
      <div class="htr-form modal-access modal-access-sent">
        <div class="htr-form modal-access modal-access-sent">
          <div class="icon-hold">
            <img src="/images/email-sent-modal.svg" alt="HotelTechReport">
          </div>
          <h2>Click the verification link sent to your inbox to verify your email</h2>
          <a id="claim-profile-modal-confirmation-toast-button" class="btn btn-blue">Back to Hotel Tech Report</a>
        </div>
      </div>
    </div>
  </div>`
  let userEmailExists = `
    <div id="user-already-exists-alert" style="display: none;" class="flex justify-center items-center">
      <div class="flex rounded-lg max-w-[450px] p-4 bg-amber-100 border-l-4 border-amber-400">
        <div class="flex-shrink-0">
          <svg class="h-5 w-5 text-amber-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
          </svg>
        </div>
        <div class="ml-3">
          <p class="text-sm text-zinc-900 font-semibold">
            A user with this email already exists. We have sent a message to your email to log in and continue your request.
          </p>
        </div>
      </div>
    </div>
  `
  let htmlContent
  if (window.Laravel.user.email !== null) {
    htmlContent = confirmationToast
    $.ajax({
      url: `/api/company-claims`,
      beforeSend: (xhr) => {
        xhr.setRequestHeader('Authorization', `Bearer ${window.Laravel.apiToken}`)
      },
      data: {
        product_id: window.company.product.id
      },
      type: 'post',
      success: (res) => {
        claimProfileModalConfirmationToast()
      }
    })
  } else {   
    htmlContent = `
      <div class="modal-wrapper">
        <div class="modal">
          <div class="htr-form modal-access">
            <h2>Claim ${companyName}'s profile</h2>
            <p class="modal-access__description">
              Create a free account to claim ${companyName}’s profile and gain access to the vendor dashboard to collect
              reviews, generate leads and more.
            </p> 
            <div class="form-box">
              <div class="form-groups">
                <label class="form-group first_name_label">
                  <input type="text" class="form-control first_name_input">
                    <span class="custom-label">
                      first name
                    </span>
                </label>
                <label class="form-group last_name_label">
                  <input type="text" class="form-control last_name_input">
                  <span class="custom-label">
                    last name
                  </span>
                </label>
              </div> 
              <label class="form-group email_label">
                <input type="text" class="form-control email_input"> 
                <span class="custom-label">
                  professional email
                </span> 
              </label> 
              <label class="form-group form-group-checkbox signup_rewind_label">
                <input type="checkbox" class="signup_rewind_input" checked> 
                <span class="custom-checkbox"></span>
                Sign up for theRewind, Hotel Tech Report's monthly newsletter
              </label>
            </div> 
            <button class="btn btn-blue btn-create-account-and-claim" onclick="window.htr_claimProfileModalSubmit()">
              Create account and claim profile
            </button>
             ${userEmailExists}
            <p>
              Already have an account? 
              <a href="/login">Login</a>
            </p> 
            <p class="form-group__note">
              By clicking the button above you agree to our
              <a href="https://help.hoteltechreport.com/legal/terms-conditions" target="_blank" rel"noopener">Terms of Use</a>
            </p>
          </div>
        </div>
      </div>
      <div id="claim-profile-modal-confirmation-toast" class="modal-wrapper" style="display: none">
        <div class="modal">
          <div class="htr-form modal-access modal-access-sent">
            <div class="htr-form modal-access modal-access-sent">
              <div class="icon-hold">
                <img src="/images/email-sent-modal.svg" alt="HotelTechReport">
              </div>
              <h2>Click the verification link sent to your inbox to verify your email</h2>
              <a id="claim-profile-modal-confirmation-toast-button" href="#" class="btn btn-blue">Back to Hotel Tech Report</a>
            </div>
          </div>
        </div>
      </div>
      ${confirmationToast}
    `
  }
  let modalContainerElement = $('#' + modalContainerId)
  
  if(!modalContainerElement.length) {
    return 0
  }
  modalContainerElement.html(htmlContent)
  modalContainerElement.show()
  
  $('.modal-wrapper').on('click', function (e) {
    if (e.target !== this) {
      return
    }
    window.htr_closeModal(modalContainerId)
  })
  
  $('#claim-profile-modal-confirmation-toast').on('click', function (e) {
    if (e.target !== this) {
      return
    }
    window.htr_closeModal(modalContainerId)
  })

  $('#claim-profile-modal-confirmation-toast-button').on('click', function (e) {
    window.htr_closeModal(modalContainerId)
  })


  window.htr_claimProfileModalSubmit = function () {
    let error = false
    // validate inputs
    if ($('.modal-wrapper .form-box .first_name_input').val().length < 3) {
      $('.modal-wrapper .form-box .first_name_label').addClass('error')
      error = true
    } else {
      $('.modal-wrapper .form-box .first_name_label').removeClass('error')
    }
    if ($('.modal-wrapper .form-box .last_name_input').val().length < 3) {
      $('.modal-wrapper .form-box .last_name_label').addClass('error')
      error = true
    } else {
      $('.modal-wrapper .form-box .last_name_label').removeClass('error')
    }
    let validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    if ($('.modal-wrapper .form-box .email_input').val().match(validRegex)) {
      $('.modal-wrapper .form-box .email_label').removeClass('error')
    } else {
      $('.modal-wrapper .form-box .email_label').addClass('error')
      error = true
    }
    if (!error) {
      $('.modal-wrapper .btn-create-account-and-claim').html('Loading ...')
      $('.modal-wrapper .btn-create-account-and-claim').prop('disabled', true)
      $.ajax({
        url: '/api/unauthenticated-company-claims',
        data: {
          first_name: $('.modal-wrapper .form-box .first_name_input').val(),
          last_name: $('.modal-wrapper .form-box .last_name_input').val(),
          email: $('.modal-wrapper .form-box .email_input').val(),
          subscribed: $('.modal-wrapper .htr-form .signup_rewind_input').is(":checked") ? 1 : 0,
          product_id: window.company.product.id
        },
        type: 'post',
        success: (res) => {
          claimProfileModalConfirmationToast()
        },
        error: (res) => {
          if (res?.status == 409) {
            userAlreadyExistsModal()
          } else {
            $('.modal-wrapper .btn-create-account-and-claim').html('Claim account and claim profile')
            $('.modal-wrapper .btn-create-account-and-claim').removeAttr('disabled')
            if (res?.responseJSON) {
              const errors = Object.keys(res.responseJSON.errors)
              let firstError
              if (Array.isArray(errors) && errors.length > 0) {
                firstError = res.responseJSON.errors[errors[0]]
              }
              if (Array.isArray(firstError) && firstError.length > 0) {
                $('.modal-wrapper .form-box .server_error_placeholder_span').html(firstError[0])
              } else {
                $('.modal-wrapper .form-box .server_error_placeholder_span').empty()
              }
            }
          }
        }
      })
    }
  }
  function claimProfileModalConfirmationToast() {
    if ($('#claim-profile-modal-confirmation-toast').length) {
      $('#claim-profile-modal-confirmation-toast').removeAttr('style')
    }
  }
  function userAlreadyExistsModal() {
    if ($('#user-already-exists-alert').length) {
      $('.modal-wrapper .btn-create-account-and-claim').hide()
      $('#user-already-exists-alert').show()
    }
  }
}
